import React, { Component } from "react";
import PropTypes from "prop-types";

import * as styles from "./card.module.scss";
import { Container } from "react-bootstrap";

class Card3DContainer extends Component {
  render() {
    const { children, ...other } = this.props;
    return (
      <Container className={styles.perspectiveCards} {...other}>
        {children}
      </Container>
    );
  }
}
class BasicCard3D extends Component {
  render() {
    return (
      <div
        className={`${this.props.fixedHeight ? styles.cardFixedHeight : ""} ${styles.cardSecondary} ${styles.card}`}
        ref={this.props.innerRef}
        {...this.props.other}
      >
        {this.props.children}
      </div>
    );
  }
}
class Card3DHeader extends Component {
  render() {
    return (
      <div className={styles.header} {...this.props.other}>
        {this.props.children}
      </div>
    );
  }
}
class Card3DContent extends Component {
  render() {
    return (
      <div className={styles.content} {...this.props.other}>
        {this.props.children}
      </div>
    );
  }
}

Card3DHeader.propTypes = { children: PropTypes.node.isRequired };
Card3DContent.propTypes = { children: PropTypes.node.isRequired };

BasicCard3D.propTypes = {
  fixedHeight: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
Card3DContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
BasicCard3D.defaultProps = {
  fixedHeight: true,
};

const Card3D = React.forwardRef((props, ref) => <BasicCard3D innerRef={ref} {...props} />);

export { Card3DContainer, Card3DContent, Card3DHeader, Card3D };
