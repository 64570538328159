import React from "react"
import Layout from "../../components/site/layout"
import SEO from "../../components/site/seo"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Card3D, Card3DContainer, Card3DContent, Card3DHeader } from "../../components/site/Card/Card3D"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => <h1 className="pt-5 text-center">{children}</h1>,
    [BLOCKS.HEADING_2]: (node, children) => <h2 className="pt-3 text-center">{children}</h2>,
    [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
    [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
    [BLOCKS.PARAGRAPH]: (node, children) => <div className="text-center">{children}</div>,
  },
  renderMark: {},
}

const _helperFindCardTitleFromListItem = (nodeList) => {
  let title = nodeList.content[0].content[0].content[0].value
  let subTitle = nodeList.content[0]?.content[1]?.content[0]?.content[0]?.content[0]?.value
  if (subTitle) title += "<br/><small>" + subTitle + "</small>"
  return title
}
const _helperFindCardContentFromParagraph = (nodeParagraph) => {
  let content = ""
  for (let index = 0; index < nodeParagraph.content.length; index++) {
    const c = nodeParagraph.content[index]
    let value = c.value
    if (c.marks) {
      c.marks.forEach((mark) => {
        if (mark.type == "bold") {
          value = "<b>" + value + "</b>"
        }
      })
    }
    content += value
  }

  return content
}
const Content = ({ data }) => {
  switch (data.nodeType) {
    case "text":
      return <p>{data.value}</p>

    default:
      return data.content.map((c) => {
        return <Content data={c}></Content>
      })
  }
}

const Cards = ({ data }) => {
  if (!data) return <></>
  let document = JSON.parse(data)
  console.log("Cards =", document)
  let cards = []
  let count = -1

  for (let i = 0; i < document.content.length; i++) {
    let b = document.content[i]
    if (b.nodeType == "unordered-list") {
      count++

      cards.splice(count, 1, {
        title: _helperFindCardTitleFromListItem(b),
        rows: [],
      })
    }

    if (b.nodeType == "paragraph") {
      cards[count].rows.push(_helperFindCardContentFromParagraph(b))
    }
  }

  return (
    <Card3DContainer id="perspectiveCards">
      {cards.map((card, index) => {
        return (
          <Card3D key={index} fixedHeight={false}>
            <Card3DHeader>
              <h3 className="pt-3 pb-2">
                <div dangerouslySetInnerHTML={{ __html: card.title }}></div>
              </h3>
            </Card3DHeader>
            <Card3DContent>
              {card.rows &&
                card.rows.map((r, indexR) => {
                  return (
                    <div className={`pt-3 ${indexR < card.rows.length - 1 ? "pb-3" : ""}`}>
                      <div dangerouslySetInnerHTML={{ __html: r }}></div>
                    </div>
                  )
                })}
            </Card3DContent>
          </Card3D>
        )
      })}
    </Card3DContainer>
  )
}

const CustomPage = ({ data }) => {
  // console.log("custom page", data);
  const [zoomImage, setZoomImage] = React.useState(false)
  if (!data || !data.contentfulPage) return <></>
  const { title, content, content2, cards, image, tags, slug, description } = data.contentfulPage


  return (
    <>
      <Layout currentPage={`/${slug}`} messageReservation={false} headerAbsolute={false}>
        <SEO title={title} description={description} />

        <div className="container">
          {content && documentToReactComponents(JSON.parse(content.raw), options)}

          {image && (
            <div className="row">
              <div className="offset-md-2 col-md-8 col-sm-12 ">
                <GatsbyImage
                  className="hovered"
                  image={image.gatsbyImageData}
                  title={image.title}
                  alt={image.description}
                  onClick={(e) => setZoomImage(true)}
                />
              </div>

              {zoomImage && (
                <div className="full-screen-image-container" onClick={() => setZoomImage(false)}>
                  <div className="full-screen-image-close" onClick={() => setZoomImage(false)}>
                    X
                  </div>
                  <GatsbyImage
                    image={image.gatsbyImageData}
                    title={image.title}
                    alt={image.description}
                    imgStyle={{ objectFit: "contain" }}
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
              )}
            </div>
          )}

          {cards && <Cards data={cards.raw}></Cards>}
        </div>

        {content2 && documentToReactComponents(JSON.parse(content2.raw), options)}
      </Layout>
    </>
  )
}

export const query = graphql`
  query CustomPageQuery($slug: String) {
    contentfulPage(slug: { eq: $slug }) {
      title
      description
      slug
      content {
        raw
      }
      content2 {
        raw
      }
      cards {
        raw
      }
      image {
        title
        description
        gatsbyImageData
      }
    }
  }
`

export default CustomPage
